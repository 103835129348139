<template>
  <div role="main" class="main">
    <transition name="fade">
      <section
        class="common-page minh-100 d-flex align-items-center"
        v-if="page === 'home'"
      >
        <div class="container">
          <div class="col-12 text-center">
            <h1 class="mb-0 text-white" style="letter-spacing: 0.3rem;">
              MOURN WITH US
            </h1>
            <h3 class="text-white text-overline">
              AN INCLUSIVE ONLINE FUNERAL EXPERIENCE
            </h3>
          </div>
        </div>
      </section>
    </transition>
    <section
      class="guestbook-registration image-page"
      v-show="page == 'profile'"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="border-l text-left">
              <p v-if="event_info != null">
                FUNERAL SERVICE: {{ event_info.detail.start_date_time }}
                {{ event_info.detail.time_zone }}<br />
                GUESTBOOK REGISTRATION
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="custom-group mb-3">
              <div class="photo-preview d-flex align-items-center">
                <div
                  class="upload-photo pointer mr-3"
                  v-if="!profile.image"
                  @click="$refs.image.click()"
                >
                  Click to add your photo
                </div>
                <div
                  class="flower pointer mr-3"
                  v-if="profile.image"
                  style="margin-bottom: .5rem"
                  @click="$refs.image.click()"
                >
                  <img :src="avatar_url" class="img-fluid round-image" />
                </div>
                <div class="photo-action text-left">
                  <input
                    type="file"
                    ref="image"
                    @change="inputFile"
                    accept="image/x-png,image/gif,image/jpeg"
                    style="display:none"
                  />
                  <h6 class="text-primary mt-2">
                    Or select and photo profile from the photos on the right
                  </h6>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="d-flex mb-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="profile.full_name"
                    placeholder="Type your name here"
                  />
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="goDashboard"
                    v-if="submit_btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower1.png')"
                  class="img-fluid"
                  @click="setImage('flower1.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower2.png')"
                  class="img-fluid"
                  @click="setImage('flower2.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower3.png')"
                  class="img-fluid"
                  @click="setImage('flower3.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower4.png')"
                  class="img-fluid"
                  @click="setImage('flower4.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower5.png')"
                  class="img-fluid"
                  @click="setImage('flower5.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower6.png')"
                  class="img-fluid"
                  @click="setImage('flower6.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower7.png')"
                  class="img-fluid"
                  @click="setImage('flower7.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower8.png')"
                  class="img-fluid"
                  @click="setImage('flower8.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower9.png')"
                  class="img-fluid"
                  @click="setImage('flower9.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower10.png')"
                  class="img-fluid"
                  @click="setImage('flower10.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower11.png')"
                  class="img-fluid"
                  @click="setImage('flower11.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower12.png')"
                  class="img-fluid"
                  @click="setImage('flower12.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower13.png')"
                  class="img-fluid"
                  @click="setImage('flower13.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower14.png')"
                  class="img-fluid"
                  @click="setImage('flower14.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower15.png')"
                  class="img-fluid"
                  @click="setImage('flower15.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower16.png')"
                  class="img-fluid"
                  @click="setImage('flower16.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower17.png')"
                  class="img-fluid"
                  @click="setImage('flower17.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower18.png')"
                  class="img-fluid"
                  @click="setImage('flower18.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower19.png')"
                  class="img-fluid"
                  @click="setImage('flower19.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower20.png')"
                  class="img-fluid"
                  @click="setImage('flower20.png')"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="footer" v-show="page == 'profile'"></div>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl } from "../../constants/config";
import { mapActions } from "vuex";
import { compressAccurately } from "image-conversion";
export default {
  data() {
    return {
      page: "home",
      profile: {
        event_id: 0,
        full_name: "",
        image: null,
        type: "default",
        profile_url: "",
      },
      avatar_url: null,
      previous_info: null,
      submit_btn: null,
      event_info: null
    };
  },
  mounted() {
    this.profile.event_id = this.getCorrectNumber(this.$route.params.id);
    this.previous_info =
      localStorage.getItem("loginInfo") != null
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;
    if (
      this.previous_info &&
      this.previous_info.role == "visitor" &&
      this.previous_info.event_id == this.profile.event_id
    ) {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push(
        "/visitor/dashboard/" + this.event_id + "?x=" + randomnumber
      );
    } else localStorage.removeItem("loginInfo");
    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
        event_id: this.profile.event_id,
      })
      .then((response) => {
        let res = response.data;
        if(res.status){
          this.eventItem = res.detail;
          this.event_info = response.data;
          this.event_info.detail.start_date_time = this.getFormatedDate();
          if (res.status === true) {
            console.log("event");
            this.event_id = res.detail.event_id;
            if (
              this.previous_info &&
              this.previous_info.role == "visitor" &&
              this.previous_info.event_id == this.profile.event_id
            ) {
              localStorage.setItem(
                "deceased_name",
                this.event_info.detail.decease_name
              );
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push(
                "/visitor/dashboard/" + this.event_id + "?x=" + randomnumber
              );
            } else localStorage.removeItem("loginInfo");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    window.setTimeout(() => {
      this.page = "";
    }, 3000);
    window.setTimeout(() => {
      this.page = "profile";
    }, 4000);
  },
  methods: {
    ...mapActions(["setLoggedInfo"]),
    getCorrectNumber(text) {
      return window.atob(text);
    },
    getFormatedDate() {
      const formateddate = new Date(this.event_info.detail.start_date_time);
      const firstdayMonth = formateddate.getMonth() + 1;
      const firstdayDay = formateddate.getDate();
      const firstdayYear = formateddate.getFullYear();
      const time = new Date(
        this.event_info.detail.start_date_time
      ).toLocaleTimeString("en", {
        timeStyle: "short",
        hour12: true,
        timeZone: this.event_info.detail.time_zone,
      });
      return (
        ("0000" + firstdayYear.toString()).slice(-4) +
        "-" +
        ("00" + firstdayMonth.toString()).slice(-2) +
        "-" +
        ("00" + firstdayDay.toString()).slice(-2) +
        " " +
        time
      );
    },
    setImage(item) {
      this.profile.profile_url = item;
      this.profile.type = "default";
      this.profile.image = item;
      this.avatar_url = require("@/assets/img/" + item);
      this.submit_btn = "allow";
    },
    inputFile(event) {
      this.profile.image = event.target.files[0];
      this.profile.type = "random";
      this.submit_btn = "allow";
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function(e) {
        ref.avatar_url = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    save() {
      if (this.profile.full_name == "") {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please input a name.",
          animation_type: "slide",
        });
        return;
      }
    },
    back() {
      this.$router.push("/home");
    },
    async goDashboard() {
      if (this.profile.image == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please select an image",
          animation_type: "slide",
        });
        return;
      }
      if (this.profile.full_name == "") {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please input a name",
          animation_type: "slide",
        });
        return;
      }
      console.log("this.profile.image?.file.name: ", this.profile.image.name);
      if (this.profile.image && this.profile.image.name !== undefined) {
        const compressedFile = await compressAccurately(this.profile.image, {
          size: 1000, // Target size in kilobytes (2MB)
          scale: 0.7, // Compression scale, 0.7 is a good starting point
        });
        const file = new File([compressedFile], this.profile.image.name, {
          type: compressedFile.type,
        });
        this.profile.image = file;
      }

      const formData = new FormData();
      for (let key in this.profile) {
        formData.append(key, this.profile[key]);
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/profile/create`, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.setLoggedInfo({
              token: res.id,
              name: this.profile.full_name,
              role: "visitor",
              event_id: this.profile.event_id,
            });
            localStorage.setItem("deceased_name", res.name);
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push(
              "/visitor/dashboard/" + this.event_id + "?x=" + randomnumber
            );
            location.reload();
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
